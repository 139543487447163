import React, { useState } from 'react';
import './Cart.css';

const Cart = ({ cartItems, removeFromCart, checkout, updateQuantity, togglePaymentType, updateOfferPrice, userSettings, clearCart, addCustomItem }) => {
  const [editingPrice, setEditingPrice] = useState({});
  const [customItemName, setCustomItemName] = useState('');
  const [customItemPrice, setCustomItemPrice] = useState('');
  const [customItemType, setCustomItemType] = useState('cash');

  const cashSubtotal = cartItems.reduce((sum, item) => 
    sum + (item.isCash ? parseFloat(item.cashPrice) * item.quantity : 0), 0);
  const creditSubtotal = cartItems.reduce((sum, item) => 
    sum + (!item.isCash ? parseFloat(item.creditPrice) * item.quantity : 0), 0);
  const total = cashSubtotal + creditSubtotal;

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  const currencySymbol = currencySymbols[userSettings?.currency || 'USD'] || '$';

  const handlePriceEdit = (item, newPrice) => {
    setEditingPrice({...editingPrice, [item.cartItemId]: newPrice});
  };

  const handlePriceEditSubmit = (item) => {
    const newPrice = parseFloat(editingPrice[item.cartItemId]);
    if (!isNaN(newPrice) && newPrice >= 0) {
      updateOfferPrice(item, newPrice, item.isCash);
    }
    setEditingPrice({...editingPrice, [item.cartItemId]: undefined});
  };

  const handleAddCustomItem = (e) => {
    e.preventDefault();
    if (customItemName && customItemPrice) {
      const price = parseFloat(customItemPrice);
      if (!isNaN(price) && price >= 0) {
        addCustomItem({
          name: customItemName,
          isCash: customItemType === 'cash',
          cashPrice: customItemType === 'cash' ? price : 0,
          creditPrice: customItemType === 'credit' ? price : 0,
          quantity: 1,
          isCustom: true
        });
        setCustomItemName('');
        setCustomItemPrice('');
        setCustomItemType('cash');
      }
    }
  };

  return (
    <div className="cart">
      <h2 className="cart-title">Your Cart</h2>
      <div className="add-custom-item">
        <h3>Add Custom Item</h3>
        <form onSubmit={handleAddCustomItem}>
          <input
            type="text"
            value={customItemName}
            onChange={(e) => setCustomItemName(e.target.value)}
            placeholder="Item Name"
            required
          />
          <div className="price-input-group">
            <input
              type="number"
              value={customItemPrice}
              onChange={(e) => setCustomItemPrice(e.target.value)}
              placeholder="Price"
              step="0.01"
              min="0"
              required
            />
            <select
              value={customItemType}
              onChange={(e) => setCustomItemType(e.target.value)}
            >
              <option value="cash">Cash</option>
              <option value="credit">Credit</option>
            </select>
          </div>
          <button type="submit" className="add-custom-item-btn">Add Item</button>
        </form>
      </div>
      {cartItems.length === 0 ? (
        <p className="cart-empty">Your cart is empty</p>
      ) : (
        <>
          <div className="cart-items-container">
            <ul className="cart-items">
              {cartItems.map((item, index) => (
                <li key={`${item._id || item.name}-${item.condition}-${item.printing}-${index}`} className="cart-item">
                  <div className="item-details">
                    <span className="item-name">{item.name}</span>
                    {editingPrice[item.cartItemId] !== undefined ? (
                      <div className="price-edit">
                        <input
                          type="number"
                          value={editingPrice[item.cartItemId]}
                          onChange={(e) => handlePriceEdit(item, e.target.value)}
                          step="0.01"
                          min="0"
                        />
                        <button onClick={() => handlePriceEditSubmit(item)}>Save</button>
                      </div>
                    ) : (
                      <div className="editable-price">
                        <span className="item-price" onClick={() => setEditingPrice({...editingPrice, [item.cartItemId]: item.isCash ? item.cashPrice : item.creditPrice})}>
                          {currencySymbol}{item.isCash ? item.cashPrice : item.creditPrice} {item.isCash ? 'Cash' : 'Credit'}
                        </span>
                        <span className="edit-price-hint">(Edit)</span>
                      </div>
                    )}
                  </div>
                  <div className="item-actions">
                    <div className="quantity-payment-wrapper">
                      <div className="quantity-control">
                        <button 
                          onClick={() => updateQuantity(item, item.quantity - 1)}
                          disabled={item.quantity <= 1}
                          className="quantity-button"
                        >
                          -
                        </button>
                        <span className="item-quantity">{item.quantity}</span>
                        <button 
                          onClick={() => updateQuantity(item, item.quantity + 1)}
                          disabled={item.quantity >= 4}
                          className="quantity-button"
                        >
                          +
                        </button>
                      </div>
                      <button 
                        className="toggle-payment-button"
                        onClick={() => togglePaymentType(item)}
                      >
                        {item.isCash ? 'Switch to Credit' : 'Switch to Cash'}
                      </button>
                    </div>
                    <button 
                      className="remove-button"
                      onClick={() => removeFromCart(item)}
                      aria-label={`Remove ${item.name} from cart`}
                    >
                      Remove
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="cart-summary">
            <div className="subtotal">
              <span>Cash Subtotal:</span>
              <span>{currencySymbol}{cashSubtotal.toFixed(2)}</span>
            </div>
            <div className="subtotal">
              <span>Credit Subtotal:</span>
              <span>{currencySymbol}{creditSubtotal.toFixed(2)}</span>
            </div>
            <div className="total">
              <span>Total:</span>
              <span>{currencySymbol}{total.toFixed(2)}</span>
            </div>
          </div>
          <div className="cart-actions">
            <button 
              className="checkout-button"
              onClick={checkout}
            >
              Proceed to Checkout
            </button>
            <button 
              className="clear-cart-button"
              onClick={clearCart}
            >
              Clear All
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
