import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CardList from './components/CardList';
import Cart from './components/Cart';
import CheckoutPage from './components/CheckoutPage';
import Infographics from './components/Infographics';
import EnquiryPopup from './components/EnquiryPopup';
import PinEntryPopup from './components/PinEntryPopup';
import { translateText, isTranslationAvailable } from './utils/openaiTranslate';
import './App.css';

export const API_URL = process.env.REACT_APP_API_URL || 'https://kiosk.tcgsync.com';
console.log('API URL:', API_URL);
console.log('REACT_APP_API_URL:', process.env.REACT_APP_API_URL);
console.log('REACT_APP_TEST_MODE:', process.env.REACT_APP_TEST_MODE);

// ... (rest of the code remains the same as in App.js)

const AppTest = () => {
  // ... (all the code from the App component)

  // Force bypass PIN entry
  const [showPinEntry, setShowPinEntry] = useState(false);
  const [isPinSetup, setIsPinSetup] = useState(true);

  console.log('AppTest is running');

  // ... (rest of the component code)

  if (showPinEntry) {
    console.log('PIN entry should be bypassed');
    return null;
  }

  // Return statement remains the same
};

export default AppTest;
