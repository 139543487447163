import React, { useState, useEffect, useCallback } from 'react';
import './CardList.css';
import Popup from './Popup';
import TableView from './TableView';
import CardPopup from './CardPopup';
import { useTranslation } from 'react-i18next';

const CardList = ({ cards, addToCart, allowedConditions, floorPrice, bulkSettings, userSettings, excludedSkus, calculatePrice, exchangeRates }) => {
  const { t } = useTranslation();
  const [selectedConditions, setSelectedConditions] = useState({});
  const [selectedPrintings, setSelectedPrintings] = useState({});
  const [quantities, setQuantities] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [prices, setPrices] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [priceAdjustments, setPriceAdjustments] = useState({});
  const [tcgPlayerLowPrices, setTcgPlayerLowPrices] = useState({});
  const [viewMode, setViewMode] = useState('table'); // 'card' or 'table'
  const [selectedCard, setSelectedCard] = useState(null);

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  const currencySymbol = currencySymbols[userSettings?.currency] || '$';

  const roundToTwoDecimals = (value) => {
    return Number(value.toFixed(2));
  };

  const calculateSteppedPrice = (condition, nmPrice) => {
    let price;
    switch (condition) {
      case 'NM': price = nmPrice; break;
      case 'LP': price = nmPrice * 0.8; break;
      case 'MP': price = nmPrice * 0.64; break;
      case 'HP': price = nmPrice * 0.512; break;
      case 'DM': price = nmPrice * 0.4096; break;
      default: price = nmPrice;
    }
    return roundToTwoDecimals(price);
  };

  const calculatePriceWrapper = useCallback((card, condition, printing) => {
    const price = calculatePrice(card, condition, printing);
    return {
      cashPrice: roundToTwoDecimals(price.cashPrice),
      creditPrice: roundToTwoDecimals(price.creditPrice)
    };
  }, [calculatePrice]);

  useEffect(() => {
    const fetchPrices = async () => {
      const newPrices = {};
      const newPriceAdjustments = {};
      const newTcgPlayerLowPrices = {};
      for (const card of cards) {
        const cardConditions = ['NM', 'LP', 'MP', 'HP', 'DM'].filter(cond => 
          allowedConditions.includes(cond) && 
          card.skus.some(sku => sku.condAbbr === cond)
        );
        const uniquePrintings = [...new Set(card.skus.map(sku => sku.printingName))];
        const condition = selectedConditions[card._id] || cardConditions[0] || '';
        const printing = selectedPrintings[card._id] || uniquePrintings[0] || '';
        const price = calculatePriceWrapper(card, condition, printing);
        newPrices[card._id] = price;
        newPriceAdjustments[card._id] = {
          cash: 0,
          credit: 0
        };
        const relevantSku = card.skus.find(sku => sku.printingName === printing);
        newTcgPlayerLowPrices[card._id] = roundToTwoDecimals(relevantSku?.lowPrice || 0);
      }
      setPrices(newPrices);
      setPriceAdjustments(newPriceAdjustments);
      setTcgPlayerLowPrices(newTcgPlayerLowPrices);
      setIsLoading(false);
    };

    fetchPrices();
  }, [cards, selectedConditions, selectedPrintings, allowedConditions, calculatePriceWrapper]);

  const handleConditionChange = useCallback((cardId, newCondition) => {
    setSelectedConditions(prev => ({ ...prev, [cardId]: newCondition }));
    const card = cards.find(c => c._id === cardId);
    if (card) {
      const printing = selectedPrintings[cardId] || card.skus[0]?.printingName || '';
      const newPrice = calculatePriceWrapper(card, newCondition, printing);
      setPrices(prev => ({
        ...prev,
        [cardId]: newPrice
      }));
      const relevantSku = card.skus.find(sku => sku.condAbbr === 'NM' && sku.printingName === printing);
      const nmPrice = relevantSku ? parseFloat(relevantSku.lowPrice) : 0;
      const steppedPrice = calculateSteppedPrice(newCondition, nmPrice);
      setTcgPlayerLowPrices(prev => ({
        ...prev,
        [`${cardId}-${newCondition}`]: steppedPrice
      }));
    }
  }, [cards, selectedPrintings, calculatePriceWrapper]);

  const handlePrintingChange = useCallback((cardId, newPrinting) => {
    setSelectedPrintings(prev => ({ ...prev, [cardId]: newPrinting }));
    const card = cards.find(c => c._id === cardId);
    if (card) {
      const condition = selectedConditions[cardId] || card.skus[0]?.condAbbr || '';
      const newPrice = calculatePriceWrapper(card, condition, newPrinting);
      setPrices(prev => ({
        ...prev,
        [cardId]: newPrice
      }));
      const relevantSku = card.skus.find(sku => sku.printingName === newPrinting);
      setTcgPlayerLowPrices(prev => ({
        ...prev,
        [cardId]: roundToTwoDecimals(relevantSku?.lowPrice || 0)
      }));
    }
  }, [cards, selectedConditions, calculatePriceWrapper]);

  const handleAddToCart = useCallback((card, condition, printing, quantity, trendPrice, skuId, isCash, adjustedPrice) => {
    addToCart(card, condition, printing, quantity, trendPrice, skuId, isCash, adjustedPrice);
  }, [addToCart]);

  const handlePriceAdjustment = useCallback((cardId, isCash, adjustment) => {
    setPriceAdjustments(prev => ({
      ...prev,
      [cardId]: {
        ...prev[cardId],
        [isCash ? 'cash' : 'credit']: roundToTwoDecimals(adjustment)
      }
    }));
  }, []);

  const closePopup = useCallback(() => {
    setShowPopup(false);
  }, []);

  const isExcluded = useCallback((card, condition, printing) => {
    const sku = card.skus.find(s => s.condAbbr === condition && s.printingName === printing);
    return sku && excludedSkus.includes(sku.skuId.toString());
  }, [excludedSkus]);

  const formatPrice = (price) => {
    return isNaN(price) ? '0.00' : price.toFixed(2);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <p>{t('Just fetching you the best prices...')}</p>
      </div>
    );
  }

  const handleRowClick = (card) => {
    setSelectedCard(card);
  };

  const closeCardPopup = () => {
    setSelectedCard(null);
  };

  return (
    <div className="card-list">
      <TableView 
        cards={cards} 
        calculatePrice={calculatePriceWrapper} 
        calculateSteppedPrice={calculateSteppedPrice}
        userSettings={userSettings} 
        onRowClick={handleRowClick}
        currencySymbol={currencySymbol}
        addToCart={handleAddToCart}
      />
      {selectedCard && (
        <CardPopup
          card={selectedCard}
          onClose={closeCardPopup}
          calculatePrice={calculatePriceWrapper}
          userSettings={userSettings}
          addToCart={handleAddToCart}
          currencySymbol={currencySymbol}
        />
      )}
      {showPopup && <Popup content={bulkSettings} onClose={closePopup} />}
    </div>
  );
};

export default React.memo(CardList);
