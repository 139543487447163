import React from 'react';
import './Popup.css';

const Popup = ({ isOpen, title, content, actions }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className="popup-content">
          {title && <h2>{title}</h2>}
          <p>{content}</p>
          <div className="popup-actions">
            {actions.map((action, index) => (
              <button key={index} onClick={action.onClick} className="popup-button">
                {action.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;