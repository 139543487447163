import React from 'react';
import { useTranslation } from 'react-i18next';
import { Search, Filter, Sliders, ShoppingCart, Plus, CreditCard, UserCheck } from 'lucide-react';
import './Infographics.css';

const InfoCard = ({ icon: Icon, title, content }) => (
  <div className="info-card">
    <div className="info-card-header">
      <div className="info-card-icon">
        <Icon size={24} />
      </div>
      <h3>{title}</h3>
    </div>
    <p>{content}</p>
  </div>
);

const Infographics = () => {
  const { t } = useTranslation();

  return (
    <div className="infographics">
      <div className="info-grid">
        <InfoCard
          icon={Search}
          title={t('1. Search for Cards')}
          content={t('Use the search bar to find specific cards.')}
        />
        
        <InfoCard
          icon={Filter}
          title={t('2. Review and Filter Results')}
          content={t('Use the games and sets filters to locate the correct card.')}
        />
        
        <InfoCard
          icon={Sliders}
          title={t('3. Adjust Price')}
          content={t('Use the slider to make any changes to the price. Ensure you select the correct condition and print type.')}
        />
        
        <InfoCard
          icon={ShoppingCart}
          title={t('4. Add to Cart')}
          content={t('Add either cash or credit to the cart.')}
        />
        
        <InfoCard
          icon={Plus}
          title={t('5. Add Custom Items')}
          content={t('Add any custom line items to the cart, e.g., bulk cards - 1000 MTG $10.')}
        />

        <InfoCard
          icon={CreditCard}
          title={t('6. Proceed to Checkout')}
          content={t('Once the cart is complete, continue to checkout.')}
        />

        <InfoCard
          icon={UserCheck}
          title={t('7. Submit Order')}
          content={t('Enter your staff name and submit the order.')}
        />
      </div>
    </div>
  );
};

export default Infographics;