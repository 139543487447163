import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import AppTest from './AppTest';
import './i18n'; // Import the i18n configuration

const root = createRoot(document.getElementById('root'));
const isTestMode = process.env.REACT_APP_TEST_MODE === 'true';

console.log('All environment variables:', process.env);
console.log('Is test mode?', isTestMode);

root.render(
  <React.StrictMode>
    {isTestMode ? <AppTest /> : <App />}
  </React.StrictMode>
);
