import React, { useState, useEffect } from 'react';
import { API_URL } from '../App';
import './CheckoutPage.css';
import Popup from './Popup';

const CheckoutPage = ({
  cartItems: initialCartItems,
  updateQuantity,
  removeFromCart,
  cancelCheckout,
  goToMainPage,
  userSettings,
  username,
  calculatePrice,
  togglePaymentType,
  resetApp
}) => {
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [localCartItems, setLocalCartItems] = useState(initialCartItems);
  const [orderCompleted, setOrderCompleted] = useState(false);
  const [customItemName, setCustomItemName] = useState('');
  const [customItemPrice, setCustomItemPrice] = useState('');
  const [customItemType, setCustomItemType] = useState('cash');
  const [staffName, setStaffName] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [isOrderComplete, setIsOrderComplete] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    window.parent.postMessage({ type: 'IFRAME_READY' }, '*');
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    setLocalCartItems(initialCartItems);
  }, [initialCartItems]);

  useEffect(() => {
    if (orderCompleted) {
      console.log('Order completed, showing popup');
      setShowConfirmationPopup(true);
    }
  }, [orderCompleted]);

  const handleMessage = (event) => {
    if (event.data.type === 'UPDATE_CART') {
      setLocalCartItems(event.data.cartItems);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!staffName.trim()) {
      setError("Please enter the staff name.");
      return;
    }
    if (!customerName.trim()) {
      setError("Please enter the customer name.");
      return;
    }
    if (!customerEmail.trim()) {
      setError("Please enter the customer email.");
      return;
    }
    setIsSubmitting(true);
    setError(null);

    const orderData = {
      staff_name: staffName.trim(),
      username,
      termsAgreed: true,
      isComplete: isOrderComplete,
      customer_name: customerName.trim(),
      customer_email: customerEmail.trim(),
      line_items: localCartItems.map(item => ({
        productId: item.productId,
        skuId: item.skuId,
        name: item.name,
        printType: item.printing,
        condition: item.condition,
        language: 'EN',
        quantity: item.quantity,
        offeredCashPrice: item.cashPrice,
        offeredCreditPrice: item.creditPrice,
        type: item.isCash ? 'Cash' : 'Credit',
        offeredPrice: item.isCash ? item.cashPrice : item.creditPrice,
        lowPrice: item.basePrice,
        isCustom: item.isCustom || false
      })),
    };

    console.log('Submitting order to:', `${API_URL}/api/orders`);
    console.log('Order data:', JSON.stringify(orderData, null, 2));

    try {
      const response = await fetch(`${API_URL}/api/orders`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(orderData),
      });

      console.log('Response status:', response.status);
      console.log('Response headers:', JSON.stringify(Object.fromEntries(response.headers), null, 2));

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      
      console.log('Order submitted successfully:', data);
      setOrderCompleted(true);
      window.parent.postMessage({ type: 'ORDER_COMPLETED', orderData: data }, '*');
    } catch (err) {
      console.error('Error submitting order:', err);
      setError(`An error occurred during checkout: ${err.message}. Please try again or contact support if the issue persists.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateQuantity = (item, newQuantity) => {
    const updatedItems = localCartItems.map(cartItem =>
      cartItem.cartItemId === item.cartItemId
        ? { ...cartItem, quantity: Math.min(Math.max(newQuantity, 1), 4) }
        : cartItem
    );
    setLocalCartItems(updatedItems);
    updateQuantity(item, newQuantity);
    window.parent.postMessage({ type: 'CART_UPDATED', cartItems: updatedItems }, '*');
  };

  const handleRemoveFromCart = (item) => {
    const updatedItems = localCartItems.filter(cartItem => cartItem.cartItemId !== item.cartItemId);
    setLocalCartItems(updatedItems);
    removeFromCart(item);
    window.parent.postMessage({ type: 'CART_UPDATED', cartItems: updatedItems }, '*');
  };

  const handleTogglePaymentType = (item) => {
    const updatedItems = localCartItems.map(cartItem =>
      cartItem.cartItemId === item.cartItemId
        ? { ...cartItem, isCash: !cartItem.isCash }
        : cartItem
    );
    setLocalCartItems(updatedItems);
    togglePaymentType(item);
    window.parent.postMessage({ type: 'CART_UPDATED', cartItems: updatedItems }, '*');
  };

  const handleAddCustomItem = (e) => {
    e.preventDefault();
    if (customItemName && customItemPrice) {
      const price = parseFloat(customItemPrice);
      if (!isNaN(price) && price >= 0) {
        const newItem = {
          name: customItemName,
          isCash: customItemType === 'cash',
          cashPrice: customItemType === 'cash' ? price : 0,
          creditPrice: customItemType === 'credit' ? price : 0,
          quantity: 1,
          isCustom: true,
          cartItemId: Date.now()
        };
        setLocalCartItems([...localCartItems, newItem]);
        window.parent.postMessage({ type: 'CART_UPDATED', cartItems: [...localCartItems, newItem] }, '*');
        setCustomItemName('');
        setCustomItemPrice('');
        setCustomItemType('cash');
      }
    }
  };

  const calculateTotal = () => {
    return localCartItems.reduce((total, item) => {
      const price = item.isCash ? item.cashPrice : item.creditPrice;
      return total + (price * item.quantity);
    }, 0);
  };

  const handleOrderCompleteChange = (e) => {
    setIsOrderComplete(e.target.checked);
    console.log('Order complete changed:', e.target.checked);
  };

  const handleStartNewOrder = () => {
    console.log('Starting new order');
    
    // Remove all items from the cart
    localCartItems.forEach(item => {
      removeFromCart(item);
    });

    // Reset local state
    setOrderCompleted(false);
    setLocalCartItems([]);
    setStaffName('');
    setCustomerName('');
    setCustomerEmail('');
    setIsOrderComplete(false);
    setShowConfirmationPopup(false);
    setError(null);
    setIsSubmitting(false);
    setCustomItemName('');
    setCustomItemPrice('');
    setCustomItemType('cash');

    // Update cart in parent component
    window.parent.postMessage({ type: 'CART_CLEARED' }, '*');

    if (typeof resetApp === 'function') {
      console.log('Calling resetApp function');
      resetApp();
    } else {
      console.log('resetApp function not available, using local reset');
    }

    // Navigate to the homepage
    if (typeof goToMainPage === 'function') {
      console.log('Navigating to homepage');
      goToMainPage();
    } else {
      console.error('goToMainPage function is not available');
    }

    console.log('New order started, cart cleared, navigated to homepage');
  };

  const renderOrderSummary = () => {
    return (
      <div className="order-summary">
        <h2>Order Summary</h2>
        <div className="add-custom-item">
          <h3>Add Custom Item</h3>
          <form onSubmit={handleAddCustomItem} className="custom-item-form">
            <input
              type="text"
              value={customItemName}
              onChange={(e) => setCustomItemName(e.target.value)}
              placeholder="Item Name"
              required
              disabled={showConfirmationPopup}
            />
            <input
              type="number"
              value={customItemPrice}
              onChange={(e) => setCustomItemPrice(e.target.value)}
              placeholder="Price"
              step="0.01"
              min="0"
              required
              disabled={showConfirmationPopup}
            />
            <select
              value={customItemType}
              onChange={(e) => setCustomItemType(e.target.value)}
              disabled={showConfirmationPopup}
            >
              <option value="cash">Cash</option>
              <option value="credit">Credit</option>
            </select>
            <button type="submit" disabled={showConfirmationPopup}>Add Item</button>
          </form>
        </div>
        <div className="order-items">
          {localCartItems.map((item) => (
            <div key={item.cartItemId} className="order-item">
              <div className="item-details">
                <span className="item-name">{item.name}</span>
                <span className="item-info">{item.condition} - {item.printing}</span>
              </div>
              <div className="item-price">
                <span>{item.isCash ? 'Cash' : 'Credit'}: ${(item.isCash ? item.cashPrice : item.creditPrice).toFixed(2)}</span>
              </div>
              <div className="item-quantity">
                <button className="quantity-btn" onClick={() => handleUpdateQuantity(item, item.quantity - 1)} disabled={item.quantity <= 1 || showConfirmationPopup}>-</button>
                <span>{item.quantity}</span>
                <button className="quantity-btn" onClick={() => handleUpdateQuantity(item, item.quantity + 1)} disabled={item.quantity >= 4 || showConfirmationPopup}>+</button>
              </div>
              <div className="item-actions">
                <button className="toggle-btn" onClick={() => handleTogglePaymentType(item)} disabled={showConfirmationPopup}>
                  Switch to {item.isCash ? 'Credit' : 'Cash'}
                </button>
                <button className="remove-btn" onClick={() => handleRemoveFromCart(item)} disabled={showConfirmationPopup}>Remove</button>
              </div>
            </div>
          ))}
        </div>
        <div className="order-total">
          <strong>Total: ${calculateTotal().toFixed(2)}</strong>
        </div>
      </div>
    );
  };

  const renderCheckoutContent = () => {
    console.log('Rendering checkout content. Order completed:', orderCompleted, 'Show confirmation popup:', showConfirmationPopup);
    return (
      <div className="checkout-page">
        <div className="checkout-header">
          <button onClick={goToMainPage} className="back-button" disabled={showConfirmationPopup}>
            <span className="back-arrow">&#8592;</span> Add More Cards
          </button>
          <h1>Checkout</h1>
        </div>
        {error && <div className="error" role="alert">{error}</div>}
        <div className="checkout-content">
          <div className="checkout-form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="staffName">Staff Name:</label>
                <input
                  type="text"
                  id="staffName"
                  value={staffName}
                  onChange={(e) => setStaffName(e.target.value)}
                  required
                  placeholder="Enter staff name"
                  className="staff-name-input"
                  disabled={showConfirmationPopup}
                />
              </div>
              <div className="form-group">
                <label htmlFor="customerName">Customer Name:</label>
                <input
                  type="text"
                  id="customerName"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  required
                  placeholder="Enter customer name"
                  className="customer-name-input"
                  disabled={showConfirmationPopup}
                />
              </div>
              <div className="form-group">
                <label htmlFor="customerEmail">Customer Email:</label>
                <input
                  type="email"
                  id="customerEmail"
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                  required
                  placeholder="Enter customer email"
                  className="customer-email-input"
                  disabled={showConfirmationPopup}
                />
              </div>
              <div className="form-group checkbox-group">
                <label>
                  <input
                    type="checkbox"
                    checked={isOrderComplete}
                    onChange={handleOrderCompleteChange}
                    disabled={showConfirmationPopup}
                  />
                  Order is complete
                </label>
              </div>
              <div className="button-group">
                <button type="submit" className="submit-button" disabled={isSubmitting || showConfirmationPopup}>
                  {isSubmitting ? 'Processing...' : 'Submit Order'}
                </button>
              </div>
            </form>
          </div>
          {renderOrderSummary()}
        </div>
        {/* Confirmation popup for successful order submission */}
        <Popup
          isOpen={showConfirmationPopup}
          title="Order Submitted"
          content="Your order has been saved successfully."
          actions={[
            { label: 'Start New Order', onClick: handleStartNewOrder }
          ]}
        />
      </div>
    );
  };

  return renderCheckoutContent();
};

export default CheckoutPage;
