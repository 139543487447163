import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './EnquiryPopup.css';

const EnquiryPopup = ({ onClose, onContinue }) => {
  const { t } = useTranslation();
  const [buylistEmail, setBuylistEmail] = useState('support@example.com');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        // Extract username from the URL
        const pathSegments = window.location.pathname.split('/');
        const username = pathSegments[1] || 'CapeFearCollectibles'; // Default to 'CapeFearCollectibles' if not found

        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-settings/${username}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const data = await response.json();
          if (data.buylistEmail) {
            setBuylistEmail(data.buylistEmail);
          }
        } else {
          console.warn("Received non-JSON response. Using default email.");
        }
      } catch (error) {
        console.error('Error fetching user settings:', error);
        setError(`Unable to fetch custom settings. Using default email.`);
      }
    };

    fetchUserSettings();
  }, []);

  const handleContinue = () => {
    const subject = encodeURIComponent('Please assess my cards');
    const body = encodeURIComponent('I would like to request an assessment of my cards.');
    window.open(`mailto:${buylistEmail}?subject=${subject}&body=${body}`, '_blank');
    onContinue();
  };

  return (
    <div className="enquiry-popup-overlay">
      <div className="enquiry-popup">
        <div className="enquiry-popup-content">
          <h2>{t('Send Enquiry')}</h2>
          {error && <p className="error-message">{error}</p>}
          <p>{t('Continuing will open your email app. Please attach your images to the email. We aim to respond within 48 hours.')}</p>
          <p>{t('Enquiry will be sent to')}: {buylistEmail}</p>
          <div className="enquiry-popup-buttons">
            <button onClick={onClose} className="cancel-button">{t('Cancel')}</button>
            <button onClick={handleContinue} className="continue-button">{t('Continue')}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryPopup;
